import React, { Suspense, lazy} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import Header from './components/Header';
import Footer from './components/Footer';
import Loader from './components/Loader';

import './App.scss';

function App() {
  const Home = lazy(() => import('./pages/Home'));
  const Portfolio = lazy(() => import('./pages/Portfolio'));
  const WhatWeDo = lazy(() => import('./pages/WhatWeDo'));
  const Article = lazy(() => import('./pages/Article'));
  const Mentor = lazy(() => import('./pages/Mentor'));
  const AboutUs = lazy(() => import('./pages/AboutUs'));
  const Contact = lazy(() => import('./pages/Contact'));
  const PageNotFound = lazy(() => import('./pages/PageNotFound'));
  const Proyect = lazy(() => import('./pages/Proyect'));
  const Booking = lazy(() => import('./pages/Booking'));
  const PersonalShopper = lazy(() => import('./pages/PersonalShopper'))
  const LegalWarning = lazy(() => import('./pages/Legal/LegalWarning'))
  const Privacy = lazy(() => import('./pages/Legal/Privacy'))
  const Blog = lazy(() => import('./pages/Blog'));

  return (
    <Router>
      <div className="App">
        <Header/>
        <Suspense fallback={<Loader className="loader__global" />}>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/portfolio">
            <Portfolio />
          </Route>
          <Route path="/quehacemos">
            <WhatWeDo />
          </Route>
          <Route path="/blog/:id">
            <Article />
          </Route>
          <Route path="/blog">
            <Blog />
          </Route>
          <Route path="/mentorias/reserva">
            <Booking />
          </Route>
          <Route path="/mentorias">
            <Mentor />
          </Route>
          <Route path="/sobrenosotros">
            <AboutUs />
          </Route>
          <Route path="/contacto">
            <Contact />
          </Route>
          <Route path="/personal-shopper">
            <PersonalShopper />
          </Route>
          <Route path="/aviso-legal">
            <LegalWarning />
          </Route>
          <Route path="/politica-privacidad">
            <Privacy />
          </Route>
          <Route exact path="/portfolio/:id" component={Proyect}></Route>
          <Route component={PageNotFound}></Route>
        </Switch>
        </Suspense>
        <Footer/>
      </div>
    </Router>

  );
}

export default App;
