import React from 'react';

import './Footer.scss';
import logoWhite from '../../assets/images/logo-white.png'
import Instagram from '../../assets/svg/instagram.svg'
import Facebook from '../../assets/images/facebook.png'
import Pinterest from '../../assets/images/pinterest.png'

function Header() {
  return (
    <div className="footer">
      <div className="footer__leftColumn">
        <img src={logoWhite} alt="" className="footer__image"/>
        <h4 className="footer__leftColumn-title">Urbatia</h4>
        <p className="footer__leftColumn-subtitle">Inversión Inmobiliaria</p>
      </div>
    <div className="footer__rightColumn">
      <div>
       {/*  <a href="https://www.instagram.com/_urbatia/" target="_blank" className="footer__icon footer__instagram">
          <img src={Instagram} alt="Instagram Icon"/>
        </a>
        <a className="footer__icon footer__facebook">
          <img src={Facebook} alt="Facebook icon"/>
        </a>
        <a className="footer__icon footer__pinterest">
          <img src={Pinterest} alt="Pinterest icon"/>
        </a> */}
      </div>
      {/* <div className="footer__separator">
      </div> */}
      <p className="footer__copyright">Copyright © 2024 URBATIA Inversión Inmobiliaria | Todos los derechos reservados</p>
      <div className="footer__policy"><a className="footer__legal" href="/aviso-legal">Aviso Legal</a> 
      <a className="footer__privacy" href="/politica-privacidad">Politica de privacidad</a></div>
    </div>
    </div>
  );
}

export default Header;
