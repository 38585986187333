import React from 'react';

import './Loader.scss';

function Loader() {

  return (
    <div className="loader"></div>

  );
}

export default Loader;
