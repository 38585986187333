import React , { useState } from 'react';
import { Link } from "react-router-dom";

import './Header.scss';
import Logo from '../../assets/images/logo.png';

function Header() {
  const [openMenu, toggleOpenMenu] = useState(false);
  return (
        <div>
          <div className="header__wrapper">
            <li className="header__logoWrapper">
              <a className="header__logoWrapper" href="/">
                <img className="header__logo" src={Logo}/>
                 <h2 className="header__title">Urbatia</h2>
              </a>
            </li>
            <ul className={`header__list ${openMenu ? 'header__list--open': ''}`}>
              <li className="header__item">
                <a className="header__link" href="/portfolio">Portfolio</a>
              </li>
 {/*              <li className="header__item">
                <a className="header__link" href="/quehacemos">Qué hacemos</a>
              </li> */}
              <li className="header__item">
                <a className="header__link" href="/mentorias">Mentorías</a>
              </li>
              {/* <li className="header__item">
                <a className="header__link" href="/blog">Blog</a>
              </li> */}
              <li className="header__item">
                <a className="header__link" href="/personal-shopper">Personal Shopper</a>
              </li>
              <li className="header__item">
                <a className="header__link" href="/sobrenosotros">Sobre Nosotros</a>
              </li>
              <li className="header__item">
                <a className="header__link" href="/contacto">Contacto</a>
              </li>
            </ul>
            <div className={`header__burger ${openMenu ? 'header__burger--open': ''}`} onClick={()=> toggleOpenMenu(!openMenu)}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>

        </div>
  );
}

export default Header;
